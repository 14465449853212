<template>
  <section class="mt-4 pb-5">
    <div class="container">
      <div class="inner_page_two_sides">
        <div class="left_side max-width-300">
          <SideMenu :config="menuConfig"/>
        </div>

        <div class="right_side news_page">
          <div class="section_payment_breadcrumbs">
            <span>Главная / Новости</span>
          </div>
          <div v-if="id">
            <Post :post="oneNews"/>
            <PostWidget :count="3" :posts="allNews" :title="'Читайте далее'"/>
          </div>
          <div v-else>
            <PostWidget :count="100" :posts="allNews"/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Post from "@/views/post/Post";
import PostWidget from "@/views/post/PostWidget";
import {mapActions, mapGetters} from "vuex";
import SideMenu from "@/components/menu/SideMenu";

export default {
  name: 'News',
  props: {
    id: {
      required: false
    }
  },

  data: function () {
    let that = this
    return {
      categoryId: null,
      menuConfig: [{
        title: "Категории",
        icon: "list-ul",
        child: [
          {
            title: "Новости",
            click: function () {
              that.categoryId = 1
              that.init()
            }
          },
          {
            title: "Обновления",
            click: function () {
              that.categoryId = 2
              that.init()
            }
          },
          {
            title: "Статьи",
            click: function () {
              that.categoryId = 3
              that.init()
            }
          }
        ]
      }]
    }
  },

  components: {
    Post, PostWidget, SideMenu
  },

  methods: {
    ...mapActions(["fetchNews", "fetchNewsList"]),
    init: function () {
      if (this.id) {
        this.fetchNews(this.id)
        this.fetchNewsList({page: 1, size: 3})
      } else {
        this.fetchNewsList({categoryId: this.categoryId, page: 1, size: 30})
      }
    }
  },

  computed: {
    ...mapGetters(["allNews", "oneNews"])
  },

  mounted: function () {
    this.init()
  }
}
</script>
