<template>
  <div>
    <div class="news_header">
      <div class="title">
        <h1>{{ post.title }}</h1>
      </div>
      <div class="image">
        <img :alt="post.title" :src="'https://storage.yandexcloud.net/mmnt-os-1/' + post.coverURL"/>
      </div>
    </div>

    <div class="news_content" v-html="post.body"/>
  </div>
</template>

<script>
export default {
  props: ['post'],

  data: function () {
    return {
      cover: 'https://via.placeholder.com/500.png?text=Test+image',
      title: 'Test title',
    }
  },

}
</script>

