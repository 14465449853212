<template>
  <div id="accordion" class="panel-group">
    <div v-for="m in config" v-bind:key="m.title" class="panel panel-default">
      <div class="panel-heading active">
        <h4 class="panel-title">
          <b-icon :icon="m.icon" variant="secondary"/>
          <span class="title">{{ m.title }}</span>
        </h4>
      </div>
      <div v-for="c in m.child" v-bind:key="c.title"
           class="panel panel-default">
        <div class="panel-heading">
          <h4 class="panel-title" @click="c.click()">
            <span>{{ c.title }}
              <span v-if="c.count" class="badge badge-light"> {{ c.count }}</span>
            </span>
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SideMenu',
  props: ['config']
}
</script>